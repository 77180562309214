
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
  name: 'FloatingLabelComponent',
  props: {
    hide: Boolean,
    mousePosition: Object as PropType<{ x: number, y: number }>,
    yearsAmount: Number,
  },
});
