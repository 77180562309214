
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeComponent',
  computed: {
    portraitPath: () => require('@/assets/pictures/zach.png'),
    cssProps(): { backgroundImage: string } {
      return {
        backgroundImage: `url(${this.portraitPath})`,
      };
    },
    arrowPath: () => require('@/assets/icons/arrow-up.svg'),
  },
});
