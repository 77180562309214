
import { defineComponent, PropType } from 'vue';

import { Experience } from '../../shared/interfaces/Experience';

export default defineComponent({
  name: 'ExperienceComponent',
  props: {
    name: String as PropType<Experience['name']>,
    role: String as PropType<Experience['role']>,
    termLength: String as PropType<Experience['termLength']>,
    descriptions: Array as PropType<Experience['descriptions']>,
    url: String as PropType<Experience['url']>,
  },
});
