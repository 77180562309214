
import { defineComponent, PropType } from 'vue';
import debounce from 'lodash.debounce';

import { Proficiency, ProficiencyType } from '../../shared/proficiencies';

export default defineComponent({
  name: 'ProficienciesComponent',
  props: {
    title: String as PropType<Proficiency['title']>,
    type: Object as PropType<ProficiencyType>,
    techs: Object as PropType<Proficiency['techs']>,
  },
  data: () => ({
    debouncedHideYearsLabel: () => null,
  }),
  created() {
    this.debouncedHideYearsLabel = debounce(() => { this.$emit('hideYearsLabel', true) }, 1500);

    const deviceTouchPoints = navigator.maxTouchPoints ?? 0;
    if (deviceTouchPoints > 0) {
      window.addEventListener('scroll', this.emitHideYearsLabel);
    }
  },
  methods: {
    getIconPath(iconFileName: string): string {
      return require(`@/assets/icons/${this.iconFolderName}/${iconFileName}`);
    },
    updateMousePosition(event: MouseEvent) {
      this.emitMousePosition({ x: event.clientX, y: event.clientY });
    },
    onTouch() {
      this.$emit('hideYearsLabel', false);
      this.debouncedHideYearsLabel();
    },
    emitHideYearsLabel() {
      this.$emit('hideYearsLabel', true);
    },
    emitTechYears(years: number) {
      this.$emit('hideYearsLabel', false);
      this.$emit('setTechYears', years);
    },
    emitMousePosition(positions: { x: number, y: number }) {
      this.$emit('updateMousePosition', positions);
    },
  },
  computed: {
    iconFolderName() {
      return this.type === ProficiencyType.Dev ? 'dev-icons' : 'editing-icons';
    },
  }
});
