
import { defineComponent, PropType } from 'vue';

import { Video } from '../../shared/videos';

export default defineComponent({
  name: 'SoundDesignComponent',
  mounted() {
    const initialWidthEvent: Partial<UIEvent> = {
      target: {
        innerWidth: window.innerWidth,
      } as Window,
    };

    this.setDisplayTitles(initialWidthEvent);
    window.addEventListener('resize', this.setDisplayTitles);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setDisplayTitles);
  },
  props: {
    url: String as PropType<Video['url']>,
    title: String as PropType<Video['title']>,
    description: String as PropType<Video['description']>,
  },
  data: () => ({
    displayTitles: false,
    displayTitlesBreakpoint: 524,
  }),
  methods: {
    setDisplayTitles(e: UIEvent | Partial<UIEvent>) {
      const windowTarget = e?.target as Window;
      if (windowTarget?.innerWidth) {
        this.displayTitles = windowTarget.innerWidth > this.displayTitlesBreakpoint;
      }
    }
  },
});
