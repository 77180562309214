
import { defineComponent } from 'vue';

import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default defineComponent({
  name: 'AppComponent',
  components: {
    HeaderComponent,
    FooterComponent,
  },
  computed: {
    condenseHeader() {
      return this.$route.fullPath !== '/';
    },
  },
});
