import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88fb7064"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video__container" }
const _hoisted_2 = { class: "video__wrapper" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "video-player__container",
  style: {"padding":"56.25% 0 0 0","position":"relative"}
}
const _hoisted_5 = ["src", "aria-label"]
const _hoisted_6 = { class: "description left-align" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (_ctx.url)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("iframe", {
              src: `${_ctx.url}${_ctx.displayTitles ? '1;' : '0;'}`,
              frameborder: "0",
              allow: "autoplay;fullscreen;picture-in-picture",
              allowfullscreen: "",
              style: {"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},
              "aria-label": 'video of ' + _ctx.title
            }, "\n      ", 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1)
  ]))
}