import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_component = _resolveComponent("header-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_component = _resolveComponent("footer-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_header_component, { "condense-header": _ctx.condenseHeader }, null, 8, ["condense-header"]),
      _createElementVNode("main", null, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, {
              name: "fade",
              mode: "out-in",
              appear: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_component, { id: "footer" })
  ]))
}