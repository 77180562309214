
import { defineComponent } from 'vue';

import ExperienceComponent from '../components/career/ExperienceComponent.vue';
import ProficienciesComponent from '../components/career/ProficienciesComponent.vue';

import { employers } from '../shared/employers';
import { educations } from '../shared/educations';
import { devProficiencies, ProficiencyType } from '../shared/proficiencies';
import useLabelDivSetup from '../shared/composables/useLabelDivSetup';
import FloatingLabelComponent from '../components/helpers/FloatingLabelComponent.vue';

export default defineComponent({
  name: 'WebDevView',
  components: {
    ExperienceComponent,
    ProficienciesComponent,
    FloatingLabelComponent
  },
  setup: () => ({ ...useLabelDivSetup() }),
  computed: {
    ProficiencyType: () => ProficiencyType,
    devProficiencies: () => devProficiencies,
    employers: () => employers,
    educations: () => educations,
  }
});
